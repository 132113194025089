import auth from './pt_br/auth';
import navbar from './pt_br/navbar';
import files from './pt_br/files';
import profile from './pt_br/profile';
import management from './pt_br/management';
import backoffice from './pt_br/backoffice';
import dias_uteis from './pt_br/dias_uteis';
import distribuidoras from './pt_br/distribuidoras';

const pt_br_translation = {
  ...auth,
  ...navbar,
  ...files,
  ...profile,
  ...management,
  ...backoffice,
  ...dias_uteis,
  ...distribuidoras
};

export var pt_br = pt_br_translation;
