import { getAuth, setAuth } from './auth.helpers';
import Config from '@/config/config';
import axios from 'axios';
//import { loginMock } from '@/mock/loginMock';

import Vue from 'vue';
import VueCookies from 'vue-cookies';
Vue.use(VueCookies);

const CONFIG = new Config();

const state = getAuth() ?? {
  user: null,
  token: null,
  authentication: null,
  acl: null,
  appVersion: null
};

export const getters = {
  isAuthenticated: (state) => !!state.token
};

export const mutations = {
  SET_CURRENT_USER(state, { user, token, acl, appVersion }) {
    state.user = user;
    state.token = token;
    state.acl = acl;
    state.appVersion = appVersion;

    setAuth({
      user,
      token,
      acl,
      appVersion
    });
  },
  LOGOUT(state) {
    state.user = null;
    state.token = null;
    state.acl = null;
    state.appVersion = null;

    setAuth({
      user: null,
      token: null,
      acl: null,
      appVersion: null
    });
  }
};

export const actions = {
  CREATE_USER({ commit, dispatch }, _payload) {
    return new Promise((resolve, reject) => {
      axios
        .post(CONFIG.API_URL + '/auth/signup', _payload)
        .then((_response) => {
          resolve(_response);
        })
        .catch((_error) => {
          reject(_error.response.data.code);
        });
    });
  },
  LOGIN({ commit }, { login, password }) {
    return new Promise((resolve, reject) => {
      let vm = this;
      let payload = {
        login,
        password
      };
      const defaultAvatar =
        'https://images.pexels.com/users/avatars/68/tim-savage-333.png?auto=compress&fit=crop&h=256&w=256';

      axios
        .post(CONFIG.API_URL_LIBRABACKOFFICE + '/auth/login', payload)
        .then((_response) => {

          /*if(login == loginMock.login && password == loginMock.password) {
           const { token, login, username, acl, version } = loginMock;*/

          let user = {};
          user.avatar = defaultAvatar;
          user.nomeCompleto = _response.data.username;
          user.nome = _response.data.username.split(' ')[0];
          user.login = _response.data.login;

          //delete _response.data.usuario;

          let appVersion = _response.data.version;
          let token = _response.data.token;
          let acl = _response.data.acl;
          commit('SET_CURRENT_USER', {
            user,
            token,
            acl,
            appVersion
          });

          let pload = {
            isAuthenticated: vm.getters['auth/isAuthenticated']
          };

          resolve(pload);
          //} else {
        })
        .catch((_error) => {
          console.log(_error)

          const code =
            typeof _error !== 'undefined' &&
              typeof _error.response !== 'undefined'
              ? _error.response.data.code
              : 401;
          const status =
            typeof _error !== 'undefined' &&
              typeof _error.response !== 'undefined'
              ? _error.response.status
              : 500;

          reject(500, status);
        });
      //}
    });
  },
logOut({ commit }) {
  return new Promise((resolve) => {
    setTimeout(() => {
      document.cookie.split(';').forEach((item) => {
        document.cookie = item
          .replace(/^ +/, '')
          .replace(
            /=.*/,
            '=;expires=' + new Date().toUTCString() + ';path=/'
          );
      });
      document.location.href = '/';
      commit('LOGOUT');
      resolve();
    }, 1000);
  });
}
};

export default {
  name: 'auth',
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};
