const listBandeirasTarifarias = () => import('./listBandeirasTarifarias.vue');
const editBandeirasTarifarias = () => import('./editBandeirasTarifarias.vue');

export default [
  {
    path: '/list-bandeiras-tarifarias',
    component: listBandeirasTarifarias
  },
  {
    path: '/edit-bandeiras-tarifarias',
    component: editBandeirasTarifarias
  }
];
