import auth from './en_us/auth';
import navbar from './en_us/navbar';
import files from './en_us/files';
import profile from './en_us/profile';

const en_us_translation = {
  ...auth,
  ...navbar,
  ...files,
  ...profile
};

export var en_us = en_us_translation;
