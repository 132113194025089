/**
 * This file was created to have all the configuration of the application
 *
 * Created by : Libra Energia
 * Support : http://www.libraenergia.com.br/
 *
 *
 */

let base22MessageStyle =
  'font-size:21px; font-weight:200; letter-spacing:0.2em; line-height:1.4em; font-family:helvetica,arial; color:#009B3A;';

console.log('%cLibra General Service', base22MessageStyle);

class Config {
  constructor() {
    this.API_URL = 'http://localhost:8081';
    this.API_URL_MANAGER = 'http://localhost:8080/libramanager/librarest';
    this.API_URL_LIBRACCEE = 'https://libracceeservice.libraenergia.systems';
    this.LIBRACCEE_TOKEN = 'KAm92#32Ab!!Amb8923Alm2erkjd';
    this.API_URL_LIBRABACKOFFICE =/*'http://localhost:8081'*/'https://librageneralservice.libraenergia.systems';
  }
}

export default Config;
