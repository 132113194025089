import browser from '@/utils/browser';

const AUTH_KEY = 'libra-auth';

export const setAuth = (data) => {
  browser.localStorage().setItem(AUTH_KEY, JSON.stringify(data));
};

export const getAuth = () => {
  const data = browser.localStorage().getItem(AUTH_KEY);
  return JSON.parse(data);
};
