let backoffice = {
  backoffice: {
    title: 'Backoffice',
    companies: 'Empresas',
    tariff: 'Tarifa Resolução',
    tariff_item: 'Itens Tarifa Resolução',
    bandeira_tarifaria: 'Bandeira Tarifária',
    modelo_tributario: 'Modelo Tributário',
    ccee_service: 'CCEE Service',
    s3_5minutos: 'S3 5 Minutos',
    qualidadeMedicoes5Min: 'Qualidade Medições 5 Minutos',
    qualidadeMedicoes: 'Qualidade Medições',
    qualidadeMedicoes1Hora: 'Qualidade Medições 1 Hora',
    s3: 'S3',
    coleta_medidas: 'Robôs Coletas',
    others: 'Outros'
  }
};

export default backoffice;
