import Vue from 'vue';
import VueRouter from 'vue-router';
import auth from '@/features/auth/auth.routes';
import archives from '@/features/archives/archives.routes';
import profile from '@/features/profile/profile.routes';
import associateUser from '@/features/management/associateUser.routes';
import companies from '@/features/companies/listEmpresas.routes';
import companiesEdit from '@/features/companies/cadastroEmpresas.routes';
import holidays from '@/features/holidays/feriado.routes';
import distribuidoras from '@/features/distribuidoras/distribuidoras.routes';
import tarifaResolucao from '@/features/tarifaResolucao/tarifaResolucao.routes';
import bandeirasTarifarias from '@/features/bandeirasTarifarias/bandeirasTarifarias.routes';
import modeloTributario from '@/features/modeloTributario/modeloTributario.routes';
import cceeService from '@/features/cceeService/cceeService.routes';
import s3_5minutos from '@/features/s3_5minutos/s3_5minutos.routes';
import qualidadeMedicoes5Min from '@/features/qualidadeMedicoes5Min/qualidadeMedicoes5Min.routes';
import coletaMedidas from '@/features/coletaMedidas/coletaMedidas.routes';
import qualidadeMedicoes1Hora from '@/features/qualidadeMedicoes1Hora/qualidadeMedicoes1Hora.routes';
import parcelaBoletaView from '@/features/registros/sys/parcelaBoletaView.routes';
import parcelaContratoCceeView from '@/features/registros/ccee/parcelaContratoCceeView.routes';
import parcelaBoletaValidation from '@/features/validacoes/parcelaBoletaValidation.routes';
import planilhaUpload from '@/features/planilhaUpload/planilhaUpload.routes';
import matchingManual from '@/features/matching/matchingManual.routes';
import netEnergetico from '@/features/net/netEnergetico.routes';
import posicaoEnergetica from '@/features/posicao/posicaoEnergetica.routes';

import { authGuard } from '@/features/auth/auth.middleware';

Vue.use(VueRouter);

const routes = [
  ...auth,
  {
    path: '/resetPassword',
    name: 'resetPassword',
    component: () => import('@/features/resetPassword/resetPassword.vue')
  },
  {
    path: '/404',
    name: '404',
    component: () => import('@/views/404.vue')
  },
  {
    path: '*',
    redirect: '404'
  },
  {
    path: '/',
    component: () => import('@/views/Main.vue'),
    children: [
      {
        path: '',
        name: 'home',
        component: () => import('@/views/Home.vue')
      },
      ...archives,
      ...profile,
      ...associateUser,
      ...companies,
      ...companiesEdit,
      ...holidays,
      ...distribuidoras,
      ...tarifaResolucao,
      ...bandeirasTarifarias,
      ...modeloTributario,
      ...cceeService,
      ...s3_5minutos,
      ...qualidadeMedicoes5Min,
      ...coletaMedidas,
      ...qualidadeMedicoes1Hora,
      ...parcelaBoletaView,
      ...parcelaContratoCceeView,
      ...parcelaBoletaValidation,
      ...planilhaUpload,
      ...matchingManual,
      ...netEnergetico,
      ...posicaoEnergetica
    ]
  }
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
});

router.beforeEach(authGuard);

export default router;
