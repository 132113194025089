const ContratoFornecedorPage = () => import('./ContratoFornecedorPage.vue');
const RelatoriosPage = () => import('./RelatoriosPage.vue');

export default [
  {
    path: '/files/contratos-energia',
    name: 'contratoFornecedorPage',
    component: ContratoFornecedorPage
  },
  {
    path: '/files/relatorios',
    name: 'relatoriosPage',
    component: RelatoriosPage
  }
];
