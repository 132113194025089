const distribuidoraListView = () => import('./listDistribuidoras.vue');
const distribuidoraEditView = () => import('./cadastroDistribuidoras.vue');

export default [
  {
    path: '/list-distribuidoras',
    component: distribuidoraListView
  },
  {
    path: '/edit-distribuidoras',
    component: distribuidoraEditView
  }
];
