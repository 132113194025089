const feriadoListView = () => import('./feriadoListView.vue');
const feriadoEditView = () => import('./feriadoEditView.vue');
const feriadoCadastroView = () => import('./feriadoCadastroView.vue');

export default [
  {
    path: '/list-feriados',
    component: feriadoListView
  },
  {
    path: '/edit-feriados',
    component: feriadoEditView
  },
  {
    path: '/cadastro-feriados',
    component: feriadoCadastroView
  }
];
