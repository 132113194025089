const ListModeloTributario = () => import('./listModeloTributario.vue');
const EditModeloTributario = () => import('./editModeloTributario.vue');

export default [
  {
    path: '/list-modelo-tributario',
    component: ListModeloTributario
  },
  {
    path: '/edit-modelo-tributario',
    component: EditModeloTributario
  }
];
