const ListEmpresas = () => import('./listEmpresas.vue');
const CadastroEmpresas = () => import('./cadastroEmpresas.vue');

export default [
  {
    path: '/list-empresas',
    component: ListEmpresas
  },
  {
    path: '/cadastro-empresas',
    component: CadastroEmpresas
  }
];
