const listTarifaResolucao = () => import('./listTarifaResolucao.vue');
const listTarifaResolucaoItem = () => import('./listTarifaResolucaoItem.vue');

export default [
  {
    path: '/list-tarifa-resolucao',
    component: listTarifaResolucao
  },
  {
    path: '/list-tarifa-resolucao-item',
    component: listTarifaResolucaoItem
  }
];
